<template>
  <div class="animated fadeIn">
    <b-btn variant="primary" size="lg" v-on:click="createPromotion()">{{
      $t("message.createPromotion")
    }}</b-btn>
    <br />
    <br />
    <b-card :title="$t('message.allPromotion')">
      <form v-on:submit.prevent="getPromotionList">
        <div class="row">
          <div class="col-sm-6 col-md-6">
            {{ $t("message.machineIdFarm") }} : <br />
            <multi-list-select
              :list="machineData"
              option-value="machineId"
              option-text="name"
              :selected-items="selectedMachine"
              placeholder="All"
              @select="onSelectMachine"
            >
            </multi-list-select>
          </div>

          <div class="col-sm-6 col-md-4">
            <br />
            <b-btn variant="success" type="submit">
              <i class="fa fa-search"></i>
              &nbsp;{{ $t("message.search") }}
            </b-btn>
          </div>
        </div>
      </form>
      <br />
      <div class="table-responsive">
        <table class="table2">
          <thead style="text-align-last: center" class="bg-primary">
            <th>{{ $t("message.machineIdFarm") }}</th>
            <th>{{ $t("message.promotion") }}</th>
            <th>{{ $t("message.period") }}</th>
            <th>{{ $t("message.deviceUsed") }}</th>
            <th>{{ $t("message.priority") }}</th>
            <th>{{ $t("message.updatedAt") }}</th>
            <th>action</th>
          </thead>
          <tbody style="text-align: center">
            <template v-for="items in promotionsList">
              <tr>
                <td>{{ items.machineId }}</td>
                <td>{{ items.name }}</td>
                <td>
                  {{ items.time[0] | moment("DD-MM-YYYY") }} -
                  {{ items.time[1] | moment("DD-MM-YYYY") }}
                </td>
                <td>{{ items.slots }}</td>
                <td>{{ items.priority }}</td>
                <td>{{ items.updatedAt | moment("YYYY-MM-DD HH:mm:ss") }}</td>
                <td>
                  <b-btn variant="success" v-on:click="editPromotion(items)">
                    <i class="fa fa-gear"></i>
                    &nbsp;{{ $t("message.setting") }} </b-btn
                  >&nbsp;
                  <b-btn
                    variant="danger"
                    @click="showModalPromotion(items)"
                    :disabled="!isAdmin(role)"
                    >{{ $t("message.delete") }}</b-btn
                  >
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </b-card>

    <b-modal ref="deletePromotion" title="ลบโปรโมชั่น" @ok="deletePromotion">
      <table class="table2">
        <tr>
          <td>{{ $t("message.machineIdFarm") }}</td>
          <td>
            {{ promotionData.machineId }}
          </td>
        </tr>
        <tr>
          <td>{{ $t("message.promotion") }}</td>
          <td>
            {{ promotionData.name }}
          </td>
        </tr>
        <tr>
          <td>{{ $t("message.period") }}</td>
          <td>
            {{ promotionData.time[0] | moment("DD-MM-YYYY") }} -
            {{ promotionData.time[1] | moment("DD-MM-YYYY") }}
          </td>
        </tr>
        <!-- <tr>
          {{promotionData}}
        </tr> -->
      </table>
    </b-modal>
  </div>
</template>

<script>
import DatePicker from "../../custom_modules/vue2-datepicker";
import moment from "moment";
import webServices from "../../script";
import { MultiListSelect } from "../../custom_modules/search-select";
export default {
  name: "EditPromotion",
  components: {
    MultiListSelect,
  },
  data() {
    return {
      searchData: {},
      machineData: [],
      selectedMachine: [],
      promotionData: {
        machineId: "",
        name: "",
        time: [],
      },
      promotionsList: [],
      role: window.localStorage.getItem("roleID"),
    };
  },
  methods: {
    isAdmin(role) {
      return webServices.isAdmin(role);
    },
    getMachineList() {
      this.$Progress.start();
      webServices
        .getMachineList(window.localStorage.getItem("userId"))
        .then((res) => {
          this.$Progress.finish();
          this.machineData = res.data;
        })
        .catch((err) => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          this.$toast.error({
            title: "ERROR",
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText,
            },
          });
          console.log("error @machine");
          console.log(err);
        });
    },
    getPromotionList() {
      this.$Progress.start();
      webServices
        .getPromotionList(this.searchData)
        .then((res) => {
          this.$Progress.finish();
          if (res.data) {
            // console.log(res.data)
            this.promotionsList = res.data;
          }
        })
        .catch((err) => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          this.$toast.error({
            title: "ERROR",
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText,
            },
          });
          console.log("error @get Promotion");
          console.log(err);
        });
    },
    onSelectMachine(items, lastSelectItem) {
      // console.log(items)
      this.selectedMachine = items;
      this.searchData.machineId = this.selectedMachine.map(
        (data) => data.machineId
      );
    },
    showModalPromotion(promotion) {
      this.promotionData = promotion;
      this.$refs.deletePromotion.show();
    },
    editPromotion(data) {
      this.$router.push({
        path: `/promotion/edit/${data.machineId}/${data.promotionId}`,
        params: { machineId: data.machineId, promotionId: data.promotionId },
      });
    },
    createPromotion() {
      this.$router.push("/promotion/create");
    },
    deletePromotion() {
      const { promotionId, machineId } = this.promotionData;
      this.$Progress.start();
      webServices
        .deletePromotion({
          promotionId: promotionId,
          machineId: machineId,
        })
        .then((res) => {
          this.$Progress.finish();
          if (res.data) {
            this.$toast.success({
              title: "SUCCESS",
              message: "สำเร็จ",
            });
            this.getPromotionList();
          } else {
            this.$toast.error({
              title: "ERROR",
              message: "ไม่สามารถลบได้",
            });
          }
        })
        .catch((err) => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          this.$toast.error({
            title: "ERROR",
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText,
            },
          });
          console.log("error @get Promotion");
          console.log(err);
        });
    },
  },
  mounted() {
    this.getMachineList();
    this.getPromotionList();
  },
};
</script>
